// store.js
import { createStore } from 'vuex';
import axios from 'axios';

const store = createStore({
  state() {
    return {
      user: null,
      selectedFont: 'default',
      headerStyle: {},
    };
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setSelectedFont(state, font) {
      state.selectedFont = font;
    },
    setHeaderStyle(state, style) {
      state.headerStyle = style;
    },
  },
  actions: {
    async fetchUser({ commit }) {
      try {
        console.log("📌📌📌")
        const response = await axios.get('/v1/userinfo', { withCredentials: true });
        const data = response.data;
        const userLevel = data.userlevel;

        if (userLevel === 'vip') {
          commit('setHeaderStyle', { background: "#e11858" });
          // 其他处理，比如连接 WebSocket
        }

        const setting = data.setting || "{}";
        const selectedFont = (() => {
          try {
            return JSON.parse(setting).font || "default";
          } catch (e) {
            return "default";
          }
        })();

        commit('setSelectedFont', selectedFont);
        commit('setUser', data);
      } catch (error) {
        console.error("获取用户信息失败：", error);
        commit('setSelectedFont', "default");
      }
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    userLevel(state) {
        return state.user ? state.user.userlevel : '';
      },
    selectedFont(state) {
      return state.selectedFont;
    },
    headerStyle(state) {
      return state.headerStyle;
    },
  },
});

export default store;
