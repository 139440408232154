import { createI18n } from 'vue-i18n'



const i18n = createI18n({

  // default locale

  locale: 'cn',



  // translations

  messages: {

    arab: {

       
        logintext:"تسجيل الدخول",
        featuretext:"وظيفة",
        doctext:"وثيقة",
        goaltreetext:"شجرة الأهداف تزيل الغموض عن الأهداف والمشاريع والمهام المستقبلية",
        goaltreefun1:"إدارة هدفك بطريقة أنيقة"
        ,goaltreefun2:"ذر هدفك إلى المشاريع والمهام"
        ,try:"جرب ti الآن",
        tasktreetext:"Task tree to help you go forward confidently",
        tasktreefun1:"Decompose your unclear or complex tasks",
        tasktreefun2:"Create a task tree what you want",
        tasktreefun3:"Top to 20 child task of parent task",
        tasktreefun4:"Drag to create task relation",
        evasystext:"Evaluation system to get real-time reward",
        evasysfunc1:"To get real-time reward of workday routine",
        evasysfunc2:"Do task like playing game",
        evasysfunc3:"Elegant review algorithm",
        evasysfunc4:"Self-define tags and review factors",
        privacytext:"We Protect your privacy",
        privacyfunc1:"Not use your data for profit or other purpose",
        privacyfunc2:"Show alias for your goal if you would",
        privacyfunc3:"project your password no leak danger",
        about:"About us",
        about1:"Our Company",
        about2:"Stories and News",
      },
  
    spa: {

        appTitle: 'Mushahed',
        logintext:"Acceso",
        featuretext:"característica",
        doctext:"documento",
        goaltreetext:"El árbol de objetivos desmitifica el objetivo, el proyecto y la tarea futuros"
        ,goaltreefun1:"Gestiona tu objetivo de forma elegante",
        goaltreefun2:"Atomiza tu objetivo en proyectos y tareas"
        ,try:"prueba ahora",
        tasktreetext:"Task tree to help you go forward confidently",
        tasktreefun1:"Decompose your unclear or complex tasks",
        tasktreefun2:"Create a task tree what you want",
        tasktreefun3:"Top to 20 child task of parent task",
        tasktreefun4:"Drag to create task relation",
        evasystext:"Evaluation system to get real-time reward",
        evasysfunc1:"To get real-time reward of workday routine",
        evasysfunc2:"Do task like playing game",
        evasysfunc3:"Elegant review algorithm",
        evasysfunc4:"Self-define tags and review factors",
        privacytext:"We Protect your privacy",
        privacyfunc1:"Not use your data for profit or other purpose",
        privacyfunc2:"Show alias for your goal if you would",
        about:"About us",
        about1:"Our Company",
        about2:"Stories and News",
      },

 
      en: {

        appTitle: 'Mushahed',
        goaltreetext:"Goal tree demystify future goal, project and task",
        logintext:"Login",
        featuretext:"Features",
        doctext:"Document"
        ,goaltreefun1:"Manage your goal using elegant way"
          ,goaltreefun2:"Atom your goal to projects and tasks"
          ,try:"Try it now",
          tasktreetext:"Task tree to help you go forward confidently",
          tasktreefun1:"Decompose your unclear or complex tasks",
          tasktreefun2:"Create a task tree what you want",
          tasktreefun3:"Top to 20 child task of parent task",
          tasktreefun4:"Drag to create task relation",
          evasystext:"Evaluation system to get real-time reward",
          evasysfunc1:"To get real-time reward of workday routine",
          evasysfunc2:"Do task like playing game",
          evasysfunc3:"Elegant review algorithm",
          evasysfunc4:"Self-define tags and review factors",
          privacytext:"We Protect your privacy",
          privacyfunc1:"Not use your data for profit or other purpose",
          privacyfunc2:"Show alias for your goal if you would",
          about:"About us",
          about1:"Our Company",
          about2:"Stories and News",
          career:"Careers",
          career1:"Join us",
          career2:"Invest us",
          termuse:"Terms Of Use",
          hadaccount:"had account"
          ,gologin:"to login"
          ,noregister:"Not registered"
          ,forgetpass:"forget password"
          ,createacc:"Create an account"
          ,resetpass:"reset password",
          create:"Create",
          knowpass:"konw password",
          reset:"reset",
          password:"password",
          setting:"setting",
          premium:"premium",
          logout:"Logout",
          makewith:" make with",
          declaim:"Join us to orgnize your tasks,projects and goals.Make it possible with us@atomTask"
      },
     cn: {
      password:"请输入密码",
      makewith:" 制作",
      setting:"设置",
      logout:"登出",
      declaim:"atomTask 是一款任务管理软件，帮助你组织你的任务、项目、目标。加入我们,一起提高执行能力和获得美好的心流体验。",
      reset:"重置",
      premium:"会员",
      create:"创建",
      knowpass:"知道密码",
      forgetpass:"忘记密码",
      noregister:"还未注册",
      createacc:"创建账户",
      resetpass:"重设密码",
      hadaccount:"已经有账户",
      gologin:"去登录",
        appTitle:'测试',
        logintext:"登录",
        featuretext:"功能",
        doctext:"文档",
        goaltreetext:"目标树揭开未来目标、项目和任务的神秘面纱",
        goaltreefun1:"使用优雅的方式管理您的目标",
        goaltreefun2:"将您的目标分解为项目和任务",
        try:"立即注册",
        tasktreetext:"任务树帮助你克服情绪上的障碍",
        tasktreefun1:"拆解复杂或者不清楚的任务",
        tasktreefun2:"以你的方式创建任务树",
        tasktreefun3:"最高20个子任务",
        tasktreefun4:"使用拖动功能来创建任务树",
        evasystext:"获得实时反馈的评估系统",
        evasysfunc1:"获取每天反馈对比的资料",
        evasysfunc2:"像玩游戏做任务的体验",
        evasysfunc3:"透明的评价算法",




        evasysfunc4:"允许自定义各类算法因子",
        
        
        recmtext:"人工智能AI,每天为你推送新的任务",
        recmfunc1:"帮助探索历史任务,轻松hold住大脑",
        recmfunc2:"算法将学习你的行为模式，为你推荐任务",
        recmfunc3:"告别漫无目的的任务搜索，我们的算法帮您找到最适合您的任务！",


        dairytext:"属于你私人定制的故事书",
        dairyfunc1:"记录你的每时每刻，独特专属记忆",
        dairyfunc2:"千人千面的UI",
        dairyfunc3:"我们能为你提供装订和其他高级付费功能",

        privacytext:"我们保护你的隐私",
        privacyfunc1:"不会将您的数据提供给广告商",
        privacyfunc2:"在一些场合可以设置别名显示",
        privacyfunc3:"密码算法加密，泄露风险低",
        about:"关于我们",
        about1:"公司",
        about2:"新闻",
        career:"建立联系",
        career1:"加入我们",
        career2:"投资我们",
        termuse:"使用条款"
    },
    cnfan: {

        logintext:"登錄",
        featuretext:"功能",
        doctext:"文檔",
        goaltreetext:"目標樹揭開未來目標、項目和任務的神秘面紗",
        goaltreefun1:"使用優雅的方式管理您的目標",
        goaltreefun2:"將您的目標分解為項目和任務"
        ,try:"現在試試",
        tasktreetext:"任務樹幫助你客服情緒上的障礙",
        tasktreefun1:"拆解複雜或者不清楚的任務",
        tasktreefun2:"以你的方式創建任務樹",
        tasktreefun3:"最高20個子任務",
        tasktreefun4:"使用拖動功能來創建任務樹",
        evasystext:"Evaluation system 幫助你獲得實時反饋",
        evasysfunc1:"獲得工作日例行的實時反饋",
        evasysfunc2:"像玩遊戲一樣做任務",
        evasysfunc3:"優雅的審核算法",
        evasysfunc4:"自定義標籤和評論因素",
        privacytext:"我們保護你的隱私",
        privacyfunc1:"不使用你的數據作為任何盈利目的",
        privacyfunc2:"在一些場合可以設置別名顯示",
        about:"關於我們",
        about1:"公司",
        about2:"新聞",
        career:"建立联系",
        career1:"加入我們",
        career2:"投資我們",
        termuse:"使用條款"
    },
      ja: {

        appTitle:'テスト',
        logintext:"ログイン",
        featuretext:"関数",
        doctext:"書類",
        goaltreetext:"目標ツリーは、将来の目標、プロジェクト、およびタスクをわかりやすく説明します",
        goaltreefun1:"エレガントな方法で目標を管理する",
        goaltreefun2:"目標をプロジェクトとタスクにアトム化する",
        try:"今すぐ試してみてください",
        tasktreetext:"任務樹幫助你客服情緒上的障礙",
        tasktreefun1:"拆解複雜或者不清楚的任務",
        tasktreefun2:"以你的方式創建任務樹",
        tasktreefun3:"最高20個子任務",
        tasktreefun4:"使用拖動功能來創建任務樹",
        evasystext:"Evaluation system 幫助你獲得實時反饋",
        evasysfunc1:"獲得工作日例行的實時反饋",
        evasysfunc2:"像玩遊戲一樣做任務",
        evasysfunc3:"優雅的審核算法",
        evasysfunc4:"自定義標籤和評論因素",
        privacytext:"我們保護你的隱私",
        privacyfunc1:"不使用你的數據作為任何盈利目的",
        privacyfunc2:"在一些場合可以設置別名顯示",
        about:"关于我们",
        about1:"公司",
        about2:"新闻",
      },
      ke:{
        appTitle:'시험',
        logintext:"로그인",
        featuretext:"특징",
        doctext:"문서",
        goaltreetext:"목표 트리는 미래의 목표, 프로젝트 및 작업을 이해하기 쉽게 설명합니다.",
        goaltreefun1:"우아한 방식으로 목표를 관리하세요",
        goaltreefun2:"프로젝트 및 작업에 대한 목표 원자화",
        try:"지금 시도해 보세요",
        tasktreetext:"任務樹幫助你客服情緒上的障礙",
        tasktreefun1:"拆解複雜或者不清楚的任務",
        tasktreefun2:"以你的方式創建任務樹",
        tasktreefun3:"最高20個子任務",
        tasktreefun4:"使用拖動功能來創建任務樹",
        evasystext:"Evaluation system 幫助你獲得實時反饋",
        evasysfunc1:"獲得工作日例行的實時反饋",
        evasysfunc2:"像玩遊戲一樣做任務",
        evasysfunc3:"優雅的審核算法",
        evasysfunc4:"自定義標籤和評論因素",
        privacytext:"我們保護你的隱私",
        privacyfunc1:"不使用你的數據作為任何盈利目的",
        privacyfunc2:"在一些場合可以設置別名顯示",
        about:"关于我们",
        about1:"公司",
        about2:"新闻",
      }
      
  },

})



export default i18n